@media (max-width:320px) { /* smartphones small */ }

@media (max-width:375px) { /* mobile M phones */ }

@media (max-width:425px) { /* large mobile phones and phonelets */

	#titleDescriptionText {
		font-size: 1.15em;
		width: 85%;
	}

	.text-color-big {
    font-size: 1.2em;
    line-height: 1.2;
    padding: 0 .25em;
	}

	// #logosContainer {
	// 	align-items: center;
	// 	display: flex;
	// 	flex-direction: column;
	// 	justify-content: center;
	// }

	.text-final-title {
		font-size: 1.2em;
		line-height: 1.2;
	}

	.text-color-normal-slim {
		font-size: 1em;
		padding: 0 .25em;
	}

	#topSectionLarge {
		background-size: 100%;
	}

	#mainSection {

		#topSection {
			min-height: 165px;
		}

		#codeForm {
			padding: 0;

			label {
				display: block;
			}

			input {
				margin-bottom: 0.5em;
				width: 80%;
			}
		}

		#editSection .row {
			display: block;

			.jvFloat  {
				width: 96%;
			}

			.jvFloat:first-of-type input {
				margin-bottom: 1.2em;
			}
		}

		#topSection p {
			font-size: 1.2em;
			margin-top: 1.5em;
		}
	}

}

@media (max-width:768px) { /* tablet, landscape iPad, lo-res laptops ands desktops */

	#summaryTable {
		display: none;
	}

}

@media (max-width:1024px) { /* big landscape tablets, laptops, and desktops */ }

@media (max-width:1440px) { /* hi-res laptops L */ }

@media (max-width:2560px) { /* 4K */ }