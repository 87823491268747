/* COLORS */
@import url("https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,800");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700");
.jvFloat .placeHolder {
  color: #1B5496;
  font-size: .95em; }

/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/* FONTS */
input:focus {
  box-shadow: 0 0 1px #F8F8F8;
  outline: none !important; }

input[type=text], input[type=email], input[type=password] {
  background-color: white;
  border: 1px solid rgba(216, 216, 216, 0.4);
  border-bottom: 2px solid #D8D8D8;
  box-sizing: border-box;
  height: 2.5em;
  padding: 0.2em 0.5em; }

input[type=submit], input[type=button] {
  background-color: #00AA8D;
  border: 1px solid #007763;
  color: #FFF;
  font-size: 1.05em;
  font-weight: 500;
  padding: 0.50em 1.8em 0.50em 1.8em; }

select {
  background-color: white;
  border: 1px solid rgba(216, 216, 216, 0.4);
  border-bottom: 2px solid #D8D8D8;
  box-sizing: border-box;
  height: 2.5em;
  padding: 0.2em 0.5em; }
  select:focus {
    box-shadow: 0 0 1px #F8F8F8;
    outline: none !important; }

#state {
  background-color: #eaeaea; }

label[for="state"] {
  display: none !important; }

/* Chrome fix for autocomplete color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #FFFFFF inset; }

/* =============================================================

    Tables v1.0
    Simple table styling by Chris Ferdinandi.
    http://gomakethings.com

    Adapted from Twitter Bootstrap.
    http://twitter.github.com/bootstrap/

    Licensed under the Apache License v2.0
    http://www.apache.org/licenses/LICENSE-2.0

 * ============================================================= */
/* =============================================================
    RESPONSIVE RESET
    Replace with your own.
 * ============================================================= */
@-webkit-viewport {
  width: device-width;
  zoom: 1.0; }

@-moz-viewport {
  width: device-width;
  zoom: 1.0; }

@-ms-viewport {
  width: device-width;
  zoom: 1.0; }

@-o-viewport {
  width: device-width;
  zoom: 1.0; }

@viewport {
  width: device-width;
  zoom: 1.0; }

html {
  overflow-y: auto; }

img, audio, video, canvas {
  max-width: 100%; }

/*  Sets Body Width */
.container {
  max-width: 40em;
  width: 88%;
  margin-left: auto;
  margin-right: auto; }

/* =============================================================
    TABLES
    Table styling.
 * ============================================================= */
/*  Removes default browser settings
 *  and evens out inconsistencies. */
table {
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

/*  Sets table width to 100%.
 *  Adds bottom-margin. */
.table {
  width: 100%;
  margin-bottom: 1.52941em; }

/*  Adds table cell padding,
 *  aligns text to the left,
 *  and adds a top border to each cell. */
.table th,
.table td {
  padding: 0.47059em;
  text-align: left;
  border-top: 0.07143em solid #b8b8b8; }

/*  Sets table header font-weight to bold.
 *  Aligns table header text to the bottom of each cell. */
.table th {
  font-weight: bold;
  vertical-align: bottom; }

/*  Aligns cell text to the top of each cell. */
.table td {
  vertical-align: top; }

/*  Removes the border-top from the first row. */
.table thead:first-child tr th,
.table thead:first-child tr td {
  border-top: 0; }

/*  Adds border-top between two table-body sections. */
.table tbody + tbody {
  border-top: 0.14286em solid #b8b8b8; }

/*  Reduces cell padding */
.table-condensed th,
.table-condensed td {
  padding: 0.29412em; }

/* Adds borders and border-radius. */
.table-bordered {
  border: 0.07143em solid #b8b8b8;
  border-collapse: separate;
  *border-collapse: collapsed;
  border-radius: 0.47059em; }

.table-bordered th + th,
.table-bordered td + td,
.table-bordered th + td,
.table-bordered td + th {
  border-left: 0.07143em solid #b8b8b8; }

.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0; }

.table-bordered thead:first-child tr:first-child th:first-child,
.table-bordered tbody:first-child tr:first-child td:first-child {
  border-radius: 0.47059em 0 0 0; }

.table-bordered thead:first-child tr:first-child th:last-child,
.table-bordered tbody:first-child tr:first-child td:last-child {
  border-radius: 0 0.47059em 0 0; }

.table-bordered thead:last-child tr:last-child th:first-child,
.table-bordered tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 0.47059em; }

.table-bordered thead:last-child tr:last-child th:last-child,
.table-bordered tbody:last-child tr:last-child td:last-child {
  border-radius: 0 0 0.47059em 0; }

#summaryTable {
  font-size: 80%; }

.breakWordTable {
  word-break: break-word; }

#footer {
  background-color: #00AA8D;
  box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.21);
  bottom: -1px;
  left: 0;
  min-height: 2vh;
  position: absolute;
  right: 0; }

body {
  color: #535353;
  font-family: "Raleway", sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

p {
  line-height: 1.75em; }

strong, b {
  font-weight: 700; }

.small {
  font-size: 0.90em;
  line-height: 1.45em; }

.text-final-title {
  color: #00AA8D;
  font-family: "Raleway", sans-serif;
  font-size: 1.5em;
  font-weight: 700; }

.text-color-superbig {
  color: #1B5496;
  font-size: 1.45em;
  font-weight: 600;
  line-height: 1.50em; }

.text-color-big {
  color: #1B5496;
  font-size: 1.40em;
  font-weight: 600;
  line-height: 1.50em;
  padding-left: .75em;
  padding-right: .75em; }

.text-green-big-slim {
  color: #1B5496;
  font-size: 1.40em;
  font-weight: 400;
  line-height: 1.50em; }

.text-green-normal {
  color: #0088C1;
  font-size: 1.15em;
  font-weight: 600;
  line-height: 1.20em; }

.text-color-normal-slim {
  color: #1B5496;
  font-size: 1.15em;
  font-weight: 500;
  line-height: 1.25em;
  margin: 1em auto;
  max-width: 30em; }

.errorclass {
  background-color: rgba(255, 30, 0, 0.08) !important;
  border-bottom: 2px solid #9C1E0E !important; }

.errorMsg {
  color: #CC1C41;
  font-weight: 600; }

#mailToLink {
  color: #1B5496; }
  #mailToLink:visited {
    color: #0088C1; }
  #mailToLink:hover {
    color: #0088C1; }
  #mailToLink:active {
    color: #0088C1; }

#header {
  background-color: #e6eff9;
  background-image: url("../images/pattern.jpg");
  background-position: center;
  background-size: cover;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.4);
  padding-bottom: 2.5em;
  position: relative;
  text-align: center;
  z-index: 1; }
  #header img {
    margin-bottom: 4.5em;
    margin-top: 2.5em;
    max-width: 56em;
    width: 95%; }

body {
  background-color: #00AA8D; }

#backContainer {
  background-color: #1B5496;
  background-image: url("../images/background.jpg");
  background-size: cover;
  min-height: 60vh;
  position: relative; }

#mainSection {
  background-color: #FFF;
  border: 3px solid #acacac;
  box-shadow: 0 2px 22px rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  max-width: 955px;
  min-height: 40vh;
  padding-bottom: 3em;
  position: relative;
  top: -6em;
  width: 95%;
  z-index: 2; }

#titleDescriptionText {
  text-align: center; }

#topSectionLarge {
  background-color: #FFF;
  background-image: url("../images/fiocchi-xl.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 2em; }

#topSection {
  background-color: #FFF;
  text-align: center; }
  #topSection p {
    margin-top: 1em; }

#logosContainer {
  text-align: center;
  padding-top: 3em; }
  #logosContainer:first-child {
    margin-left: -2em; }
  #logosContainer img {
    margin: 2% 2%;
    max-width: 22%;
    display: inline-block; }
    #logosContainer img:last-child {
      max-width: 16%; }

#codeSection {
  background-color: #F8F8F8;
  border: 2px solid #00AA8D;
  border-radius: 6px;
  margin: 0 auto;
  max-width: 80%;
  text-align: center;
  padding-bottom: 3em; }
  #codeSection input[type=text] {
    margin-left: 1.7em;
    margin-right: 1.7em;
    padding-left: 0;
    text-align: center;
    width: 20%; }

#editSection {
  background-color: #F8F8F8;
  border-bottom: 2px solid #D8D8D8;
  border-top: 2px solid #D8D8D8;
  padding: 0 5%; }

#confirmSection {
  background-color: #F8F8F8;
  border: 2px solid #00AA8D;
  border-radius: 6px;
  margin: 0 auto;
  max-width: 80%;
  padding-bottom: 1.5em;
  padding-top: 1.5em;
  text-align: center; }

#disclaimerSection {
  padding: 1.25em 2.2em 1.25em 2.2em;
  text-align: center; }

#loginSection {
  margin-bottom: 1.5em;
  margin-top: 3.5em;
  text-align: center; }
  #loginSection input[type=email], #loginSection input[type=password] {
    width: 30%; }

#exportSection {
  padding: 2em 0.8em 2em 0.8em;
  text-align: center; }

@media (max-width: 320px) {
  /* smartphones small */ }

@media (max-width: 375px) {
  /* mobile M phones */ }

@media (max-width: 425px) {
  /* large mobile phones and phonelets */
  #titleDescriptionText {
    font-size: 1.15em;
    width: 85%; }
  .text-color-big {
    font-size: 1.2em;
    line-height: 1.2;
    padding: 0 .25em; }
  .text-final-title {
    font-size: 1.2em;
    line-height: 1.2; }
  .text-color-normal-slim {
    font-size: 1em;
    padding: 0 .25em; }
  #topSectionLarge {
    background-size: 100%; }
  #mainSection #topSection {
    min-height: 165px; }
  #mainSection #codeForm {
    padding: 0; }
    #mainSection #codeForm label {
      display: block; }
    #mainSection #codeForm input {
      margin-bottom: 0.5em;
      width: 80%; }
  #mainSection #editSection .row {
    display: block; }
    #mainSection #editSection .row .jvFloat {
      width: 96%; }
    #mainSection #editSection .row .jvFloat:first-of-type input {
      margin-bottom: 1.2em; }
  #mainSection #topSection p {
    font-size: 1.2em;
    margin-top: 1.5em; } }

@media (max-width: 768px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  #summaryTable {
    display: none; } }

@media (max-width: 1024px) {
  /* big landscape tablets, laptops, and desktops */ }

@media (max-width: 1440px) {
  /* hi-res laptops L */ }

@media (max-width: 2560px) {
  /* 4K */ }

#editSection .row {
  display: flex;
  margin: 1.35em 2%; }
  #editSection .row .jvFloat {
    flex: 1;
    margin: 0 2%; }
  #editSection .row-small-input .jvFloat:first-child {
    flex: 3; }
  #editSection .row-small-input.reverse {
    flex-direction: row-reverse; }
  #editSection .row input, #editSection .row select {
    width: 100%; }

#editSection .centerAlign {
  margin-bottom: 2em;
  text-align: center; }

#titleDescriptionText {
  color: #0088C1;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  margin: 1.25em auto;
  max-width: 24em;
  padding-left: 1em;
  padding-right: 1em; }
  #titleDescriptionText b {
    color: #1B5496; }

#descriptionText {
  margin-left: 11%;
  margin-top: 2.40em;
  width: 75%; }

#codeForm {
  padding-left: 2.2em; }
