#header {
	background-color: $color-blue-lightest;
	background-image: url('../images/pattern.jpg');
	background-position: center;
	background-size: cover;
	box-shadow: 0px 8px 25px rgba(0, 0, 0, .4);
	padding-bottom: 2.5em;
	position: relative;
	text-align: center;
	z-index: 1;

	img {
		margin-bottom: 4.5em;
		margin-top: 2.5em;
    max-width: 56em;
    width: 95%;
	}
}