input {
	&:focus{
		box-shadow: 0 0 1px $grey-light;
		outline: none !important;
	}
	&[type=text], &[type=email], &[type=password] {
		background-color: white;
		border: 1px solid rgba($color-gray-light, .4);
		border-bottom: 2px solid $color-gray-light;
		box-sizing: border-box;
		height: 2.5em;
		padding: 0.2em 0.5em;
	}
	&[type=submit], &[type=button] {
		background-color: $green;
		border: 1px solid darken($green, 10%);
		color: $white;
			font: {
			size: 1.05em;
			weight: 500;
		}
		padding: 0.50em 1.8em 0.50em 1.8em;
	}
}

select {
	background-color: white;
	border: 1px solid rgba($color-gray-light, .4);
	border-bottom: 2px solid $color-gray-light;
	box-sizing: border-box;
	height: 2.5em;
	padding: 0.2em 0.5em;

	&:focus{
		box-shadow: 0 0 1px $grey-light;
		outline: none !important;
	}
}

#state {
	background-color: lighten($color-gray-light, 7%);
}

label[for="state"] {
	display: none !important;
}

/* Chrome fix for autocomplete color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #FFFFFF inset;
}