#editSection {
	.row {
		display: flex;
		margin: 1.35em 2%;
		.jvFloat {
			flex: 1;
			margin: 0 2%;
		}
		&-small-input {
			.jvFloat:first-child {
				flex: 3;
			}
			&.reverse {
				flex-direction: row-reverse;
			}
		}
		input, select {
			width: 100%;
		}
	}
	.centerAlign {
		margin-bottom: 2em;
		text-align: center;
	}
}