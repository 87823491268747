/* COLORS */

$color-blue: #1B5496;
$color-blue-light: #0088C1;
$color-blue-lightest: #e6eff9;

$color-gray-light: #D8D8D8;

$gold-light: #DFC75A;
$gold-medium: #D1B435;
$gold: #BE942E;
$green-dark: #0B3B17;
$green-transparent: rgba(21, 89, 36, 0.28);
$green: #00AA8D;
$grey-dark: #777;
$grey-light: #F8F8F8;
$grey-med-light: #D1D1D1;
$grey: #535353;
$red: #CC1C41;
$red-dark: #9C1E0E;
$red-dark-transparent: rgba(255, 30, 0, 0.08);
$white: #FFF;

