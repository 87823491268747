#titleDescriptionText {
	color: $color-blue-light;
	font-family: $poppins-font-stack;
	font-weight: 400;
	line-height: 1.2;
	margin: 1.25em auto;
  max-width: 24em;
  padding-left: 1em;
  padding-right: 1em;
	b {
		color: $color-blue;
	}
}

#descriptionText {
	margin: {
		left: 11%;
		top: 2.40em;
	}
	width: 75%;
}

#codeForm {
	padding-left: 2.2em;
}