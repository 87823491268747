// ----------------------------------------
// // ------ tables config ------
// ----------------------------------------

// ----------------------------------------
// // ------ colors ------
// ----------------------------------------

$table-border-color: #b8b8b8;

// ----------------------------------------
// // ------ demo container settings ------
// ----------------------------------------

$container-width: 88%;
$container-max-width: 40em;

// ----------------------------------------
// // ------ table settings ------
// ----------------------------------------

$table-border-width: .071428571em;
$table-border-style: solid;
$table-margin-bottom: 1.529411765em;
$table-cell-padding: .470588235em;
$table-cell-text-align: left;
$table-cell-border-top: $table-border-width $table-border-style $table-border-color;
$table-header-font-weight: bold;
$table-body-border-top: .142857143em $table-border-style $table-border-color;
$table-condensed-padding: .294117647em;
$table-bordered-border: $table-border-width $table-border-style $table-border-color;
$table-bordered-border-radius: .470588235em;
$table-border-left: $table-border-width $table-border-style $table-border-color;
$table-bordered-child-radius: .470588235em;

/* =============================================================

    Tables v1.0
    Simple table styling by Chris Ferdinandi.
    http://gomakethings.com

    Adapted from Twitter Bootstrap.
    http://twitter.github.com/bootstrap/

    Licensed under the Apache License v2.0
    http://www.apache.org/licenses/LICENSE-2.0

 * ============================================================= */


/* =============================================================
    RESPONSIVE RESET
    Replace with your own.
 * ============================================================= */

@-webkit-viewport { width: device-width; zoom: 1.0; }
   @-moz-viewport { width: device-width; zoom: 1.0; }
    @-ms-viewport { width: device-width; zoom: 1.0; }
     @-o-viewport { width: device-width; zoom: 1.0; }
        @viewport { width: device-width; zoom: 1.0; }

html { overflow-y: auto; }

img, audio, video, canvas { max-width: 100%; }

/*  Sets Body Width */
.container {
    max-width: $container-max-width;
    width: $container-width;
    margin-left: auto;
    margin-right: auto;
}





/* =============================================================
    TABLES
    Table styling.
 * ============================================================= */

/*  Removes default browser settings
 *  and evens out inconsistencies. */
table {
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

/*  Sets table width to 100%.
 *  Adds bottom-margin. */
.table,
%table {
    width: 100%;
    margin-bottom: $table-margin-bottom;
}

/*  Adds table cell padding,
 *  aligns text to the left,
 *  and adds a top border to each cell. */
.table th,
%table th,
.table td,
%table td {
    padding: $table-cell-padding;
    text-align: $table-cell-text-align;
    border-top: $table-cell-border-top;
}

/*  Sets table header font-weight to bold.
 *  Aligns table header text to the bottom of each cell. */
.table th,
%table th {
    font-weight: $table-header-font-weight;
    vertical-align: bottom;
}

/*  Aligns cell text to the top of each cell. */
.table td,
%table td {
   vertical-align: top;
}

/*  Removes the border-top from the first row. */
.table thead:first-child tr th,
%table thead:first-child tr th,
.table thead:first-child tr td,
%table thead:first-child tr td {
    border-top: 0;
}

/*  Adds border-top between two table-body sections. */
.table tbody + tbody,
%table tbody + tbody {
    border-top: $table-body-border-top;
}

/*  Reduces cell padding */
.table-condensed th,
%table-condensed th,
.table-condensed td,
%table-condensed td {
    padding: $table-condensed-padding;
}

/* Adds borders and border-radius. */
.table-bordered,
%table-bordered {
    border: $table-bordered-border;
    border-collapse: separate;
    *border-collapse: collapsed;
    border-radius: $table-bordered-border-radius;
}

.table-bordered th + th,
%table-bordered th + th,
.table-bordered td + td,
%table-bordered td + td,
.table-bordered th + td,
%table-bordered th + td,
.table-bordered td + th,
%table-bordered td + th {
    border-left: $table-border-left;
}

.table-bordered thead:first-child tr:first-child th,
%table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
%table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td,
%table-bordered tbody:first-child tr:first-child td {
    border-top: 0;
}

.table-bordered thead:first-child tr:first-child th:first-child,
%table-bordered thead:first-child tr:first-child th:first-child,
.table-bordered tbody:first-child tr:first-child td:first-child,
%table-bordered tbody:first-child tr:first-child td:first-child {
    border-radius: $table-bordered-child-radius 0 0 0;
}

.table-bordered thead:first-child tr:first-child th:last-child,
%table-bordered thead:first-child tr:first-child th:last-child,
.table-bordered tbody:first-child tr:first-child td:last-child,
%table-bordered tbody:first-child tr:first-child td:last-child {
    border-radius: 0 $table-bordered-child-radius 0 0;
}

.table-bordered thead:last-child tr:last-child th:first-child,
%table-bordered thead:last-child tr:last-child th:first-child,
.table-bordered tbody:last-child tr:last-child td:first-child,
%table-bordered tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 $table-bordered-child-radius;
}

.table-bordered thead:last-child tr:last-child th:last-child,
%table-bordered thead:last-child tr:last-child th:last-child,
.table-bordered tbody:last-child tr:last-child td:last-child,
%table-bordered tbody:last-child tr:last-child td:last-child {
    border-radius: 0 0 $table-bordered-child-radius 0;
}

#summaryTable {
    font-size: 80%;
}

.breakWordTable {
    word-break: break-word;
}