body {
	background-color: $green;
}

#backContainer {
	background-color: $color-blue;
	background-image: url('../images/background.jpg');
	background-size: cover;
	min-height: 60vh;
	position: relative;
}

#mainSection {
	background-color: $white;
	border: 3px solid lighten($grey, 35%);
	box-shadow: 0 2px 22px rgba(0,0,0,0.12);
	margin: 0 auto;
	max-width: 955px;
	min-height: 40vh;
	padding-bottom: 3em;
	position: relative;
	top: -6em;
	width: 95%;
	z-index: 2;
}

#titleDescriptionText {
	text-align: center;
}

#topSectionLarge {
	background-color: $white;
	background-image: url('../images/fiocchi-xl.png');
	background-position: top right;
	background-repeat: no-repeat;
	background-size: cover;
	padding-bottom: 2em;
}

#topSection {
	background-color: $white;
	text-align: center;
	p {
		margin-top: 1em;
	}
}

#logosContainer {
	text-align: center;
	padding-top: 3em;

	&:first-child {
		margin-left: -2em;
	}

	img {
		margin: 2% 2%;
		max-width: 22%;
		display: inline-block;
		// &:first-child {
		// 	max-width: 28%;
		// }
		&:last-child {
			max-width: 16%;
		}
	}
}

#codeSection {
	background-color: $grey-light;
	border: 2px solid $green;
	border-radius: 6px;
  margin: 0 auto;
  max-width: 80%;
  text-align: center;
	input[type=text] {
		margin-left: 1.7em;
		margin-right: 1.7em;
		padding-left: 0;
		text-align: center;
		width: 20%;
	}
	padding-bottom: 3em;
}

#editSection {
	background-color: $grey-light;
	border-bottom: 2px solid $color-gray-light;
	border-top: 2px solid $color-gray-light;
	padding: 0 5%;
}

#confirmSection {
	background-color: $grey-light;
	border: 2px solid $green;
	border-radius: 6px;
  margin: 0 auto;
  max-width: 80%;
	padding-bottom: 1.5em;
	padding-top: 1.5em;
	text-align: center;
}

#disclaimerSection {
	padding: 1.25em 2.2em 1.25em 2.2em;
	text-align: center;
}

#loginSection {
	margin-bottom: 1.5em;
	margin-top: 3.5em;
	text-align: center;
	input[type=email], input[type=password] {
		width: 30%;
	}
}

#exportSection {
	padding: 2em 0.8em 2em 0.8em;
	text-align: center;
}
