body {
	color: $grey;
	font: {
		family: $raleway-font-stack;
		size: 1em;
	}
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#container {}

p {
	line-height: 1.75em;
}

strong, b {
	font-weight: 700;
}

.small {
	font-size: 0.90em;
	line-height: 1.45em;
}

.text-final-title  {
	color: $green;
	font-family: $raleway-font-stack;
	font-size: 1.5em;
	font-weight: 700;
}

.text-color-superbig {
	color: $color-blue;
	font: {
		size: 1.45em;
		weight: 600;
	}
	line-height: 1.50em
}

.text-color-big {
	color: $color-blue;
	font-size: 1.40em;
	font-weight: 600;
	line-height: 1.50em;
	padding-left: .75em;
	padding-right: .75em;
}

.text-green-big-slim {
	color: $color-blue;
	font: {
		size: 1.40em;
		weight: 400;
	}
	line-height: 1.50em
}

.text-green-normal {
	color: $color-blue-light;
	font: {
		size: 1.15em;
		weight: 600;
	}
	line-height: 1.20em
}

.text-color-normal-slim {
	color: $color-blue;
	font: {
		size: 1.15em;
		weight: 500;
	}
	line-height: 1.25em;
  margin: 1em auto;
  max-width: 30em;
}

.errorclass {
	background-color: $red-dark-transparent !important;
	border-bottom: 2px solid $red-dark !important;
}

.errorMsg {
	color: $red;
	font-weight: 600;
}

#mailToLink {

	color: $color-blue;

    &:visited {
      color: $color-blue-light;
    }
    &:hover {
      color: $color-blue-light;
    }
    &:active {
      color: $color-blue-light;
    }
}